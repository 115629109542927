<template>
  <div class="user">
      <div class="line_two">
        <div class="buttonArea">
          <div class="add" @click="goAdd()">
            <i class="el-icon-plus"></i>
            新增Banner
          </div>
        </div>
        <el-table :data="dataUserList" style="width: 97%" class="data_list" v-loading="loading" :header-cell-style="getRowClass">
        <el-table-column type="index" label="序号" sortable width="100" align="center"/>
         <el-table-column label="名称" prop="name" align="center" width="180"/>
        <el-table-column label="Banner" width="180" align="center">
          <template v-slot="{ row }">
            <div class="flex">         
              <img :src="row.banner" style="width:120px;height:80px"/>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="链接" prop="bannerLink" align="center"/>
        <el-table-column label="操作" align="center" width="180">
          <template v-slot="{ row }">
            <div class="caoZuo">
              <div class="colorOne" style="margin-right:10px" @click="goEdit(row)">编辑</div>
              <div class="colorOne red" @click="deleteOne(row.id)">删除</div>
            </div>
          </template>   
        </el-table-column>
      </el-table>
      </div>
      <!-- 添加或修改 -->
      <el-dialog
        :visible.sync="dialogVisible"
        width="40%"
        @close="handleCloseMenu"
        center
        :close-on-click-modal="false"
      >
        <div class="tip">
          <div class="tipOne"></div>
          {{dialogType ? '编辑Banner' : '新增Banner'}}
        </div>
        <div class="item_content">
          <el-form :model="menuObj" label-width="100px" ref="form" :rules="rules">
            <el-form-item label="名称:" prop="name">
              <el-input v-model="menuObj.name" placeholder="请输入名称" class="inputItem"/>
            </el-form-item>
            <el-form-item label="Banner" prop="banners">
              <div class="ImgArea">
                <div class="showImg">
                  <upload-image
                  :fileList="imageUrlList"
                  @updateImgTwo="updateImgTwo"
                  @updateImgShowTwo="updateImgShowTwo"
                  @removeImg="emitImgRemove"
                  />
                </div>
              </div>
            </el-form-item>
            <el-form-item label="链接" prop="bannerLink">
              <div class="editBar">
                 <el-input v-model="menuObj.bannerLink" placeholder="请输入链接" class="inputItem" type="textarea" :autosize="{ minRows: 4, maxRows: 6}"/>
              </div>
            </el-form-item>
          </el-form>
          <div class="btn_area">
            <div class="btn" @click="editRole()" v-if="this.dialogType">修改</div>
            <div class="btn" @click="addRole('form')" v-if="!this.dialogType">保存</div>
            <div class="btn" @click="handleCloseMenu()" style="margin-left:20px">取消</div>
          </div>
        </div>
      </el-dialog>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.current"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
  </div>
</template>
<script>
import UploadImage from '@/components/UploadImage.vue'
import { pagination } from '@/mixins/pagination'
import { getBannerList, deleteBanner, addBanner,editBanner  } from '@/api/banner.js'
const defaultQueryInfo = Object.freeze({
  current: 0,
  size: 10,
  key:''
})
export default {
  name: 'User',
  mixins: [pagination],
  components: { UploadImage},
  data() {
    return {
      dataUserList: [],
      dialogVisible:false,
      dialogType:false,
      menuObj:{},
      imageUrlList:[],
      imgList:[],
      detailList: [],
      selectionArr: [],
      loading: false,
      dataList:{},
      queryInfo: { ...defaultQueryInfo },
      rules:{
        name:[{ required: true, message: '请输入名称', trigger: 'blur' }],
        banners:[{ required: true, message: '请上传图片', trigger: 'blur' }],
        bannerLink:[{ required: true, message: '请输入链接 ', trigger: 'blur' }],
      },
    }
  },
  created() {
    this.search()
  },
  methods: {
    // 弹窗关闭
    handleCloseMenu(){
      this.dialogVisible = false
      this.menuObj = {}
    },
    // 新增
    goAdd(){
      this.imageUrlList = []
      this.dialogType = false
      this.dialogVisible = true
    },
    // 编辑
    goEdit(row){
      // console.log(row);
      this.dialogType = true
      this.dialogVisible = true
      this.menuObj = {
        bannerLink: row.bannerLink,
        id: row.id,
        name: row.name
      }
      this.imageUrlList = row.banners.map(el => {
        return {
          url: el,
          name: el
        }
      })
    },
    async editRole(){
      this.menuObj.banners = []
      // console.log(this.imageUrlList,'this.imageUrlList');
      this.imageUrlList.map(item=>{
        this.menuObj.banners.push(typeof(item.url) == 'object' ? item.url[0] : item.url)
      }) 
      // console.log(this.menuObj,'this.menuObj');
      const { data: res } = await editBanner(this.menuObj)
      if (res.resultCode === 200) {
        this.$message.success('修改成功')
        this.handleCloseMenu()
        this.search()
      }else {
        this.$message.error('操作失败，请重试。')
      }

    },
    async addRole(formName){
      this.menuObj.banners = []
      this.imageUrlList.map(item=>{
        this.menuObj.banners.push(typeof(item.url) == 'object' ? item.url[0] : item.url)
      }) 
      // console.log(this.menuObj," console.log(this.menuObj);");
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await addBanner(this.menuObj)
          if (res.resultCode === 200) {
            this.$message.success('添加成功!')
            this.handleCloseMenu()
            this.search()
          }
        } else {
          this.$message.error('请填写必要的选项!')
          return false;
        }
      });

    },
    //重置查询条件
    reset() {
      this.queryInfo = {
        pageNum: 0,
        pageSize: 10,
      }
      this.search()
    },
    async search() {
      this.loading = true
      const { data: res } = await getBannerList(this.queryInfo)
      if (res.resultCode === 200) {
        this.dataUserList = res.data.list
        this.total = res.data.total
        this.dataUserList.map(item=>{
          if(item.banner) item.banner = item.banner.split(',')[0]
        })
        this.loading = false
      }
    },
    async deleteOne(id){
      this.$confirm(`确认删除所Banner吗 ?`, {
          type: 'warning'
      }).then(async() => {
        const { data: res } = await deleteBanner({id:id})
        if (res.resultCode === 200) {
          this.$message.success(`删除成功`)
          this.search()
        } else {
          this.$message.error('删除失败，请重试。')
        }
        })
        .catch(() => {})
    },
    //多个图片上传
    updateImgTwo(val) {
      this.imgList = val
      // console.log(this.imgList,'imgList');
    },
    //多个图片上传显示
    updateImgShowTwo(val) {
      this.imageUrlList = val
      // console.log(this.imageUrlList,"updateImgShowTwo");
    },
    //多个图片上传移除
    emitImgRemove(val) {
      this.imageUrlList = this.imageUrlList.filter((item) => {
        return item !== val
      })
      // console.log( this.imageUrlList,"ad");
    }
  }
}
</script>

<style lang="less" scoped>
.inputItem{
  width: 350px;
}
.tip {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #333333;
    display: flex;
    margin: -25px 0px 20px 25px;
    .tipOne {
      margin: 3px 6px 0px 0px;
      background: #7c71e1;
      border-radius: 11px;
      width: 4px;
      height: 18px;
    }
  }
.item_content{
  margin: 20px 0px 0px 20px;
  .bottom_tip{
    font-size: 12px;
    color: #7C71E1;
    margin: 10px 0px 40px 100px;
  }
  .editBar{
    width: 350px;
  }
}
.colorOne{
  color: #7C71E1;
  cursor: pointer;
}
.user {
  width: 100%;
  overflow: hidden;
  height: auto !important;
  margin: 15px 0px 0px 20px;
  .line_two{
    background: #ffffff;
    margin-top: 20px;
    overflow: hidden;
    border-radius: 12px;
    .buttonArea{
      display: flex;
      margin-top: 20px;
      div{
        width: 120px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        border-radius: 4px;
        margin-left: 20px;
        cursor: pointer;
      }
      .add{
        background: #7C71E1;
        color: #ffffff;
      }
    }
  }
  .caoZuo{
    display: flex;
    justify-content: center;
    .colorOne{
      font-size: 12px;
      color: #7C71E1;
      cursor: pointer;
    }
    .red{
      color: #FF2828;
    }
  }
  .new_page {
    width: 100%;
    text-align: center;
    margin: 30px auto;
  }
  .data_list {
    width: 100%;
    margin: 20px 0px 0px 15px;
  }
}
.btn_area{
  margin: 60px 0 20px 100px;
  width: 100%;
  display: flex;
  .btn{
    color: #fff;
    text-align: center;
    line-height: 40px;
    width: 136px;
    height: 40px;
    background: #7C71E1;
    border-radius: 4px;
    cursor: pointer;
  }
}

</style>
