import request from '@/utils/request'

//系统管理--Banner管理--分页查询Banner
export function getBannerList(data) {
  return request({
      url: '/banner/page',
      method: 'post',
      data
  })
}

//系统管理--Banner管理--添加Banner
export function addBanner(data) {
  return request({
      url: '/banner/add',
      method: 'post',
      data
  })
}

//系统管理--Banner管理--删除Banner
export function deleteBanner(data) {
  return request({
      url: '/banner',
      method: 'get',
      params:data
  })
}

//系统管理--Banner管理--Banner详情
export function getBannerDetail(data) {
  return request({
      url: '/banner/byId',
      method: 'get',
      params:data
  })
}

//系统管理--Banner管理--更新Banner
export function editBanner(data) {
  return request({
      url: '/banner',
      method: 'PUT',
      data
  })
}

